
// Fonts
@import url('https://fonts.googleapis.com/css?family=Indie+Flower');
@import url('https://fonts.googleapis.com/css?family=Raleway:200,300,400');
@import url('https://fonts.googleapis.com/css?family=Roboto:300');



html, body {
    background-color: #fff;
    font-weight: 200;
    height: 100vh;
    margin: 0;
    letter-spacing: 0.1em;
    font-family: 'Raleway', sans-serif;
    color: #555;

    .zoom-wrapper {
      overflow: hidden;
      margin:0;
      padding: 0;

      .grow { transition: all 0.9s ease-in-out; }
      .grow:hover { transform: scale(1.1); }
  }
}

.fixed-nav-bar {
  position: fixed;
  top: 0;
  left:0;
  z-index: -2;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
}

#logo {

  padding-right: 15px;
  padding-left: 15px;

  #firstHeadline {
      font-weight: 300;
      font-size: 1.2em;
      padding-top: 5px;
      margin-bottom: 12px;
      color: #666;
      font-family: 'Roboto', sans-serif;
    }

  hr {
    margin: 0;
    background-color: #666;
    height: 0.8px;
    border: none;
  }


  #secondHeadline {
    font-weight: 300;
    font-size: 1.8em;
    margin: 12px 0 3px 0;
    font-family: 'Roboto', sans-serif;


    img {
      z-index: 1071;
    }
  }
}

#header-wrapper {
  min-height:86vh;
  width:100%;
  z-index:-1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -3;
}

header {
  position:relative;
  min-height:85vh;
  width:100%;
  background-image:url('../images/header-werkstatt.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index:-1;
}

  

#progress {
  position:relative;
  height: 1vh;
  width: 100%;
  bottom:9vh;
  background: rgba(182,15,39,0.8);
  z-index:1999;
}

#go {
  position:relative;
  bottom:32px;
  width:100%;
  text-align:center;
  z-index:2000;
}

#go:hover {
  font-size: 1.1em;
}


span {
  i {
    color: #fff;
  }
}


#kontakt {
  margin-top: 22px;
  padding-top:0;
  padding-left: 15px;

  i {
    color: #C5161C;
  }

  a:hover {
    .fa-circle {
      color: rgba(171, 8, 16, 1);
      transform: scale(1.2);
    }
  }
}


#welcome {
  h3 {
    font-weight: 300;
    font-size: 2em;
    margin: 2.2em 0 1em 0;
  }

  p {
    font-size: 1.2em;
    padding: 0 15px;
    color: #111;
  }

  h4 {
    font-family: 'Indie Flower', cursive;
    font-size: 1.6em;
    padding: 1em 0 0 0;
    color: #555;
  }
}

#Leistungen {
  h3 {
    font-weight: 300;
    font-size: 2em;
    margin: 2.5em 0 1em 0;
    padding: 1em;
    background-color: #f6f6f6;

  }
}

#leistungen-images {
  .img-wrapper {
    position: relative;
    padding: 0px;

    img {
      z-index: 999;
    }
  }

  h5 {
        font-weight: 300;
        font-size: 1.3em;
        margin:0;
        padding: 12px 0;
        text-align: left;
        text-transform: uppercase;
        color: #999;
  }
      
  p {
        font-size: 1.1em;
        font-weight: 300;
        text-align: left;
        margin-bottom: 2em;
  }
}


#team {
  h3 {
    font-weight: 300;
    font-size: 2em;
    margin: 2.5em 0 1em 0;
    padding: 1em;
    background-color: #f6f6f6;
  }

  h4 {
    font-size: 1.6em;
    color: #555;
    padding-top: 1.3em;
    font-weight: 300;
  }

  p {
    font-size: 1.2em;
   padding: 15px 0;
    color: #111;
  }

  img {
      padding: 15px;
  }
}



footer {
  background-color: #333;
  color: #fff;
  margin-top: 2em;
  font-size: 1.1em;
  letter-spacing: 0.1em;
  padding:0;
  font-weight: 300;

  .wrapper {
    padding:3em 0;

  }


  h6 {
    margin:0;
    padding-bottom: 0.8em;
    font-size: 1.3em;
    font-weight: 300;
  }

  .contact {

    a {
      cursor:pointer;
      color: #fff;
      font-weight: 300;

      i {
        color: #333;
      }
    }

    a:hover {
      text-decoration: none;
      font-weight: 300;
      color: #ccc;

      i {
        color: #fff;
        padding-left: 0.4em;
        color: #ccc;
      }
    }
  }
}


#button {
  min-height: 8vh;
  background-color: #222;

  i {
    color: #fff;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    cursor: pointer;
  }
}

#impressum {
  h1 {
    font-weight: 400;
    font-size: 2em;
    padding: 1em;
    background-color: #f6f6f6;
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.5em;
  }

  p {
    font-size: 1.1em;
    color: #111;
  }

  h4 {
    font-size: 1.5em;
    color: #555;
    padding-top: 1.3em;
    padding-left: 15px;
    font-weight: 200;
  }

  .btn {
    background-color: #C5161C;
    color: #fff;
    border: none;
  }
  
  a {
    color: #C5161C;
  }
}

#img-footer {
    position:relative;
    min-height:30vh;
    width:100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index:9999;

    #img-text-footer {
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10000;
      font-size: 1.8em;
      color: #fff;
      font-weight: 400;
  }
}


@media (max-width: 768px) {
  .tuevsiegel {
    padding-top: 4em;
  }
  .wrapper {
    padding:15px;
  }

  .time {
    img {
      padding-top: 1em;
    }
  }

  #img-text-footer {
      font-size: 10px;
    }

    #impressum {
      h1 {
        font-size: 1.45em;
      }
    }

    #img-footer {
      min-height: 50vh;
    }
}

@media (max-width: 576px){
  #leistungen-images {
    h5 {
      font-size: 1.2em;
    }
  }
}




